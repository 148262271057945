@import "./variables";

.swiper-container {
  height: inherit;
  overflow: hidden;

  @media (min-width: $screen-xs-max + 1) {
    border-radius: 4px;
  }

  .swiper-wrapper,
  .swiper-slide {
    height: inherit;
  }
}

.sliderImageRatio .swiper-slide {
  &::before {
    content: "";
    display: block;
    width: 100%;
    padding-top: (3 / 4.8) * 100%;
  }

  > img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.swiper-pagination-bullet {
  background-color: $brand-muted;
  border-radius: 50%;
  display: inline-flex;
  width: 8px;
  height: 8px;

  & + & {
    margin-left: 4px;
  }
}

.swiper-pagination-bullet-active {
  background-color: $brand-color;
}

// Navigation
//
.swiper-button-next,
.swiper-button-prev {
  background-repeat: no-repeat;
  background-color: #fff;
  background-size: 1.8rem;
  background-position: center;
  border-radius: 50%;
  box-shadow: 0 2px 4px hsla(0, 0%, 0%, .15);
  width: 40px;
  height: 40px;
  z-index: 2;

  @media (max-width: 768px) {
    display: none;
  }

  &:hover {
    box-shadow: 0 2px 4px 2px hsla(0, 0%, 0%, .15);
    cursor: pointer;
  }

  &.swiper-button-disabled {
    opacity: 0;
    transition: opacity .24s, visibility .2s .3s;
    visibility: hidden;
  }

  &::after {
    content: none;
  }
}

.swiper-button-next {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M5 13h11.86l-3.63 4.36a1 1 0 0 0 1.54 1.28l5-6a1.19 1.19 0 0 0 .09-.15c0-.05.05-.08.07-.13A1 1 0 0 0 20 12a1 1 0 0 0-.07-.36c0-.05-.05-.08-.07-.13a1.19 1.19 0 0 0-.09-.15l-5-6A1 1 0 0 0 14 5a1 1 0 0 0-.64.23 1 1 0 0 0-.13 1.41L16.86 11H5a1 1 0 0 0 0 2z' /%3E%3C/svg%3E");
  transform: translateX(-.5rem);

  @media (max-width: 768px) {
    right: 0;
  }
}

.swiper-button-prev {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M19 11H7.14l3.63-4.36a1 1 0 1 0-1.54-1.28l-5 6a1.19 1.19 0 0 0-.09.15c0 .05 0 .08-.07.13A1 1 0 0 0 4 12a1 1 0 0 0 .07.36c0 .05 0 .08.07.13a1.19 1.19 0 0 0 .09.15l5 6A1 1 0 0 0 10 19a1 1 0 0 0 .64-.23 1 1 0 0 0 .13-1.41L7.14 13H19a1 1 0 0 0 0-2z' /%3E%3C/svg%3E");
  transform: translateX(.5rem);

  @media (max-width: 768px) {
    left: 0;
  }
}


.swiper-navigation-button {
  background-image: none;

  .icon {
    width: 1.4em;
    height: 1.4em;
  }
}

/* Slider Thumbnail Settings */
.swiper-container-thumbs {
  .swiper-slide {
    opacity: .4;
    cursor: pointer;
    padding-bottom: 10px;
    height: 60px;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 4px;
      background-color: transparent;
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .swiper-slide-thumb-active {
    opacity: 1;

    &::after {
      background-color: map-deep-get($colors, "brand-colors", "secondary");
    }
  }
}

// Slider Pagination
.swiper-pagination {
  background-color: #0008;
  border-radius: 8px;
  display: block;
  padding-block: 2px;
}

.swiper-pagination-bullet {
  --bullet-size: 5px;

  opacity: .7;
  margin-inline: 2px !important;
  width: var(--bullet-size);
  height: var(--bullet-size);

  &.swiper-pagination-bullet-active {
    background-color: map-deep-get($colors, "brand-colors", "primary");
    transform: scale(1.2);
  }
}
